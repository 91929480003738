@use '~@moved/ui/src/balazs/common/all' as *;

.start_over {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
