@use '~@moved/ui/src/sondheim/common' as *;

.card {
  width: 100%;
  border: 1px solid transparent;
}

.clickable {
  cursor: pointer;
}

.complete {
  background-color: $backgroundSuccessLight;
  border: 1px solid $borderSuccess;
  svg {
    --color-1: #{$iconOnColor};
    --color-2: #{$iconMultiSuccess};
  }
}
