@use '~@moved/ui/src/sondheim/common' as *;

.sideImage {
  background-color: $backgroundTertiary;
  flex: 0 0 auto;
  position: relative;
  @include responsive(mobile) { display: none; }
  img {
    width: 312px;
    height: auto;
    display: block;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 $sp-48 0;
}

@keyframes bounce {
  0% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0); }
}

.pin {
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes fade-in-out {
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
}

.pinShadow {
  position: absolute;
  z-index: 1;
  top: calc(50% - $sp-48);
  left: 50%;
  height: 8px;
  width: 24px;
  background: $black-100;
  border-radius: 50%;
  transform: translateX(-50%) translateY(52px);
  filter: blur(5px);
  animation: fade-in-out 1.5s infinite ease-in-out;
}
