@use '~@moved/ui/src/sondheim/common' as *;

.blue_box {
  border-radius: $radius-12;
  border: 1px solid $borderAccentLight;
  background-color: $backgroundAccentLight;
  padding: $sp-8 $sp-16;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $sp-16;
}
