@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint([xs, sm]) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.uploader {
  user-select: none;
  flex: 1 1 auto;
  max-width: 600px;
  @include breakpoint([xs,sm]) {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.requirements {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  width: 335px;
  max-width: 100%;
  margin-left: 20px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $med;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(21, 22, 24, 0.101961);
    margin-bottom: 20px;
    & > span {
      margin-left: 10px;
    }
  }

  ul {
    padding-left: 20px;
    line-height: 26px;
    li {
      &::marker {
        font-size: $font-size-4;
        color: $black-20;
      }
      font-size: $font-size-1;
      color: $black-100;
    }
  }

  @include breakpoint([xs,sm]) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.badge_wrapper {
  margin-left: 20px;
  @include breakpoint([xs,sm]) {
    background-color: $black-20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 16px 24px 36px;
    margin: 24px 0 0 0;
    width: 100%;
  }
}

.preview_title {
  display: none;
  @include breakpoint([xs,sm]) {
    display: block;
    color: $black-60;
    font-size: $font-size-2;
    margin-bottom: 28px;
    width: 100%;
  }
}

.badge {
  background-color: $white;
  border-radius: 8px;
  padding: 13px 25px 25px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
  width: 220px;
  max-width: 100%;

  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 346px;

  @include breakpoint([xs,sm]) {

  }
}

.tab {
  width: 30px;
  height: 6px;
  margin-bottom: 45px;
  background: $black-20;
  display: block;
  border-radius: 9px;
}

.preview {
  width: 120px;
  height: 120px;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.name {
  margin-top: 16px;
  font-size: $font-size-4;
  font-weight: $regular;
}

.logo {
  margin-top: 50px;
  width: 115px;
  max-width: 100%;
}
