@use '~@moved/ui/src/sondheim/common' as *;

.train {
  height: 70px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rail {
  margin: 0 40px;
  height: 6px;
  border-radius: 3px;
  max-width: 50%;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.station {
  background-color: $white;
  height: 100%;
  width: 0;
  border-radius: 3px;
  margin-right: $sp-4;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
  &.complete {
    background-color: $black-30;
  }
}

.final {
  .station.complete {
    background-color: $green-40;
  }
}
