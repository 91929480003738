@use '~@moved/ui/src/sondheim/common' as *;

.listItem {
  // unfortunately drag and drop library does not support flex gaps
  margin-bottom: $sp-12;
  // &:last-child {
  //   this breaks drag & drop layout in the current library
  //     (account for extra bottom margin on parent container)
  //   margin-bottom: 0;
  // }
}

.congratulations {
  border: 1px solid $borderTertiary;
  border-radius: $radius-8;
}

.header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: $sp-8 0;
}

.taskTitle {
  align-items: center;
  padding-left: $sp-24;
  flex: 1 1 auto;
}

.taskDetails > * {
  flex: none;
  width: 150px;
  padding-left: $sp-16;
}

.taskActions {
  width: calc(150px + $sp-24 + $sp-24 + $sp-24 + $sp-24 + $sp-24 + $sp-16); // specific width to maintain column alignment
}
