@use '~@moved/ui/src/sondheim/common' as *;

.stopsList {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-color: $backgroundTertiary;
    width: 2px;
    left: 19px;
    top: 24px;
    bottom: 24px;
    z-index: 1;
  }
}

.stopIcon {
  width: 40px;
  height: 40px;
  padding: $sp-8;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}
