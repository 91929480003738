@use '~@moved/ui/src/balazs/common/all' as *;

$enterDuration: 200;
$enterDelay: 0;
$exitDuration: 200;
$exitDelay: 800;
$staggerDelay: 150;

:export {
  enterDuration: $enterDuration;
  enterDelay: $enterDelay;
  exitDuration: $exitDuration;
  exitDelay: $exitDelay;
}

.container {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 20;
  @include breakpoint(xs) {
    display:none; // temporarily hide on mobile for demo
  }
}
.launcher {
  position: relative;
  height: 54px;
  width: 54px;
  border: 2px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 25px rgba(0,0,0,0.3);
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  overflow: hidden;
  background: $white;
  &:hover, &:active {
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    transform: scale(1.25);
  }
  &:active {
    transition: box-shadow 0.1s, transform 0.1s;
    transform: scale(1);
  }
}
.thumbnail {
  position: absolute;
  height: 50px;
  width: 50px;
  transition: all 0.3s 0.3s;
  border-radius: 50%;
  .open & {
    transition: all 0.3s;
    opacity: 0;
  }
}
.close {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s;
  margin: 17px;
  .open & {
    opacity: 1;
    transition: all 0.3s 0.3s;
    transform: scale(1);
  }
}
.contact_form {
  // position (collapsed)
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  // sizing (collapsed)
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 50%;
  // display
  background: $white;
  box-shadow: 0 0 10px rgba(0,0,0,0.0);
  overflow: hidden;
  // children
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // animations
  transform: scale(1.25);
  transition: all #{$exitDuration}ms cubic-bezier(0.250, 0.460, 0.450, 0.940) #{$exitDelay}ms;
  &.open {
    // position
    bottom: 75px;
    // sizing
    width: 350px;
    height: 375px;
    // display
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    transform: scale(1);
    transition: all #{$enterDuration}ms cubic-bezier(0.250, 0.460, 0.450, 0.940) #{$enterDelay}ms;
  }
  @for $i from 1 through 4 {
    & > *:nth-child(#{$i}) {
      transition-delay: #{$staggerDelay * $i}ms;
    }
    &.open > *:nth-child(#{$i}) {
      transition-delay: #{$exitDelay - ($staggerDelay * $i)}ms;
    }
  }
}
@mixin scaleIn {
  opacity: 0;
  transform: scale(0.75);
  transition: opacity 300ms, transform 300ms;
  .open & {
    opacity: 1;
    transform: scale(1);
  }
}
.title {
  line-height: 16px;
  text-align: center;
  @include scaleIn;
}
.subtitle {
  line-height: 15px;
  font-size: $font-size-1;
  color: $black-40;
  margin-top: 10px;
  text-align: center;
  @include scaleIn;
}
.body {
  width: 100%;
  padding: 26px 0;
  transform-origin: center 15%;
  @include scaleIn;
}
.input {
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: $black-90;
  resize: none;
  width: 100%;
  height: 200px;
  outline: none;
  transition: background 0.3s;
  overflow-y: auto;
  &::placeholder {
    color: $black-40;
  }
  &:active {
    background-color: $bg-light;
  }
}
.actions {
  text-align: center;
  width: 100%;
  @include scaleIn;
}
.check {
  background-color: $green-10;
  border-radius: 50%;
  padding: 20px;
  display: inline-block;
  margin-bottom: 15px;
  @include scaleIn;
}
