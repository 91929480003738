@use '~@moved/ui/src/balazs/common/all' as *;

.ad_section {
  margin-bottom: 46px;
  :global(.react-horizontal-scrolling-menu--inner-wrapper) {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    /*
    This element and the child below it require overflow:scroll and overflow-y:hidden
    So in order to show the scale effect for hover and the drop shadow, we expand
    this element and add equivalent padding to the child below:
    */
    margin-top: -20px;
    margin-bottom: -30px;
  }
}

.ads_scroller {
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: max(calc(50vw - 570px),20px);
  padding-right: max(calc(50vw - 570px),20px);
  /* Hide scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}

.ad_block {
  height: 100%;
  width: 100%;
}
.ad_spacer {
  flex: 0 0 20px;
}

.ad_item {
  flex: 0 0 335px;
  margin: 0;
  &.flex {
    flex: 0 1 50%;
  }
  @include breakpoint([xs]) {
    // width: 85vw;
    flex: 0 0 85vw;
    &.flex {
      flex: 0 1 100%;
    }
  }
  & > div {
    height: 100%; // ensure each block has the same height as the tallest one
  }
}

.scroll_header {
  display: flex;
  margin-bottom: 20px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  justify-content: space-between;
  @include breakpoint([xs]) {
    margin-bottom: 14px;
    h1 {
      font-size: $font-size-4;
      line-height: 24px;
    }
  }
  .icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 8px;
    --color-1: #{$white};
    --color-2: #{$green-50};
    @include breakpoint([xs]) {
      margin-top: 2px;
      margin-left: 6px;
    }
  }
}
.scroll_arrows {
  display: flex;
  &.hidden {
    display: none;
  }
}
.arrow {
  flex: 0 0 auto;
  align-self: center;
  padding: 12px;
  cursor: pointer;
  border: 1px solid $black-30;
  border-radius: 999em;
  transition: transform 0.1s ease-in, opacity 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &.left {
    padding: 12px 13px 12px 11px;
    margin-right: 15px;
    @include breakpoint([xs]) {
      padding: 8px 9px 8px 7px;
      margin-right: 10px;
    }
  }
  &.right {
    padding: 12px 11px 12px 13px;
    @include breakpoint([xs]) {
      padding: 8px 7px 8px 9px;
    }
  }
  &.disabled {
    cursor: default;
    opacity: 0.4;
    &:hover {
      transform: none;
    }
  }
  @include breakpoint([xs]) {
  }
}
