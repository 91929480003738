@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.building_logo {
  filter: brightness(0) invert(1);
  margin-bottom: 10px;
  img {
    display: block;
    max-height: 80px;
    max-width: 350px;
    @include breakpoint(xs) {
      max-height: 40px;
      max-width: 175px;
    }
  }
}

.greeting {
  font-size: $font-size-8;
  font-weight: 500;
  line-height: 1;
  @include breakpoint([xs]) {
    font-size: $font-size-7;
  }
}

.dashboard {
  background-color: $black-10;
  position: relative;
}

.transition_wrapper {
  position: relative;
  margin-top: 40px;
  z-index: 2; // show tooltip over move step navigation
  @include breakpoint([xs]) {
    margin-top: 35px;
  }
}

.tasks_section {
  margin-bottom: 70px;
}
