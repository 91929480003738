@use '~@moved/ui/src/sondheim/common' as *;

.ad_block {
  padding: $sp-8 $sp-8 $sp-20;
  border-radius: $radius-12;
  box-shadow: $shadow-2;
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: $shadow-3;
  }
}

.graphic_block {
  @include aspectRatio(16,9);
  border-radius: $radius-8;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 102px;
  max-width: 102px;
  border-bottom-left-radius: $radius-8;
}

.overlay {
  position: absolute;
  bottom: $sp-16;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
  .ad_block:hover & {
    opacity: 1;
  }
}

.content_area {
  padding: 0 $sp-20;
}
