@use '~@moved/ui/src/balazs/common/all' as *;

.company_card {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  padding: 20px 24px;
  cursor: pointer;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  margin-bottom: 16px;
  border: 1px solid transparent;
  transition: border-color linear 0.2s;

  &:last-child {
    margin-bottom: 0;
  }

  .company_icon {
    height: 56px;
    width: 56px;
    object-fit: contain;
    border-radius: 28px;
    margin-right: 20px;
    opacity: 0.9;
  }

  .company_content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .content_row {
    display: flex;
    align-items: center;
  }

  .company_name {
    color: $black-90;
    font-size: 18px;
    line-height: 24px;
  }
  .tag {
    // box model
    height: 16px;
    line-height: 16px;
    padding: 0 6px;
    margin-left: 8px;
    border-radius: 8px;
    // text
    font-size: $font-size-0;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    // colors
    background-color: $green-20;
    color: $green-40;
    @include breakpoint(xs) {
      display: none;
    }
  }

  .reviews_row {
    color: $black-90;
    font-size: $font-size-1;
    font-weight: 300;
    line-height: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .company_stars {
    margin-right: 4px;
  }
  .stars_small {
    font-size: $font-size-2;
  }

  .company_reviews_count {
    color: $black-40;
    margin-left: 4px;
    margin-right: 16px;
    @include breakpoint(xs) {
      flex: 1 1 auto;
      margin-right: 8px;
    }
  }

  .review_link {
    display: flex;
    flex: 0 0 auto;
  }
  .review_link_text {
    @include breakpoint(xs) {
      display: none;
    }
  }
  .review_link_icon {
    margin-right: 3px;
    flex: 0 0 auto;
  }

  .company_rate {
    display: flex;
    flex-direction: column;
    text-align: right;
    label {
      color: $black-40;
      font-size: $font-size-1;
      font-weight: 300;
      line-height: 16px;
    }
    span {
      color: $black-90;
      font-size: $font-size-4;
      line-height: 22px;
    }
  }

  .item_check {
    flex: 0 0 auto;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-left: 34px;
    border: 2px solid rgba(0,0,0,0.08);
    background-color: rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: none;
    }
    @include breakpoint(xs) {
      display: none;
    }
  }

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.06);
    border-color: $green-30;
  }

  &.selected_item {
    border-color: $green-40;
    .item_check {
      background-color: $green-40;
      border-color: $green-40;
      svg {
        display: block;
      }
    }
    &:hover {
      border-color: $green-30;
    }
  }
}

.primary {
  flex: 0 1 340px;
}
