@use '~@moved/ui/src/sondheim/common' as *;

.section {
  margin-bottom: 48px;
  @include breakpoints(xs) {
    margin-bottom: 32px;
  }
}

.company_card {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: $radius-8;
  padding: $sp-20 $sp-24;
  cursor: pointer;
  box-shadow: $shadow-2;
  border: 1px solid transparent;
  transition: border-color linear 0.2s, box-shadow 0.2s;

  .icon {
    margin-right: 20px;
    @include responsive(mobile) { display: none; }
  }

  .reviews {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .checkmark {
    flex: 0 0 auto;
    margin-left: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive(mobile) { display: none; }
  }

  &:hover {
    box-shadow: $shadow-3;
    border-color: $blue-30;
  }

  &.selected_item {
    border-color: $blue-40;
    &:hover {
      border-color: $blue-30;
    }
  }
}

.progress {
  --color-1: #{$borderAccent};
  --color-2: #{$borderSecondary};
}

.primary {
  min-width: 340px;
}
