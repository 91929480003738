@use '~@moved/ui/src/sondheim/common' as *;

$headerHeight: 60px; // design specific value

.spacer {
  flex: 0 0 $headerHeight;
}
.mobileHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  max-height: 100%;
  background: $backgroundSecondary;
  z-index: 50;
}

.headerBar {
  height: $headerHeight;
  padding: $sp-12 $sp-18;
  display: flex;
  align-items: center;
}

.menuIcon {
  position: absolute;
  inset: $sp-12 auto auto $sp-18;
  height: 36px; // non-standard design specific size
  width: 36px; // non-standard design specific size
  z-index: 2;
}

.collapsible {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
  &.expanded {
    height: calc(100vh - $headerHeight);
  }
}
