@use '~@moved/ui/src/sondheim/common' as *;

.container {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  height: 52px;
  @include responsive(mobile) { height: auto; }
}

.moveStepsHolder {
  position: absolute;
  top: -90px;
  z-index: 1;
  padding: $sp-8;
  border-radius: $radius-12;
  width: 100%;
  @include responsive(mobile) {
    position: relative;
    flex-direction: column;
    top: 0;
    border-radius: 0;
    gap: $sp-0;
  }
}

.stepLink {
  padding: $sp-20;
  border-radius: $radius-6;
  cursor: pointer;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 rgba(0,0,0,0.1);
  background: $backgroundSecondary;
  display: flex;
  align-items: center;
  flex: 0 1 50%;
  min-width: 0;
  user-select: none;
  @include responsive(mobile) {
    padding: $sp-12;
    flex: 1;
  }
  &:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  &.active {
    background: $backgroundTertiary;
  }
}

.thumbnail {
  height: 86px; // custom size
  width: 86px; // custom size
  background-size: cover;
  background-color: $backgroundInverseSecondary;
  background-position: center;
  border: 1px solid $borderSecondary;
  border-radius: $radius-4;
  margin-right: $sp-24;
  flex: 0 0 auto;
  @include responsive(mobile) {
    height: 52px; // custom size
    width: 52px; // custom size
    margin-right: $sp-12;
  }
}

.column {
  flex: 1 1 auto;
  min-width: 0; // needed for text truncation
  padding-right: $sp-12;
}
