@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Day / Time Specific Styles
---------------------------------------- */

.reschedule {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.history_ticket {
  background-color: rgba(255,255,255,0.85);
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 25px;
  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
  }

  .title_wrapper {
    margin-bottom: 40px;
  }

  .title_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
      font-size: $font-size-1;
    }
    .pill {
      border-radius: 20px;
      padding: 4px 10px;
      background-color: $red-20;
      color: $red-60;
      font-size: $font-size-0;
      line-height: $font-size-0;
      font-weight: $med;
      text-transform: uppercase;
      letter-spacing: 1px;
      &.orange {
        background-color: $orange-20;
        color: $orange-60;
      }
    }
  }

  .note_box {
    margin-top: 16px;
    .note_text {
      margin-left: 14px;
    }
    h6 {
      font-size: $font-size-2;
      margin-bottom: 2px;
    }
  }
}
