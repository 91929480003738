@use '~@moved/ui/src/balazs/common/all' as *;

// select keys
.roommates_form {
  max-width: 500px;
}

// pickup options
.card_wrapper {
  position: relative;
}

.card_item_wrapper {
  margin-bottom: 16px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  transition: box-shadow linear 0.2s;
  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.06);
  }
}

.card_item {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 2px transparent;
  &:hover {
    box-shadow: 0px 0px 0px 2px $green-20;
  }
}

.card_content {
  padding: 24px;
  width: 100%;
}

.content_top {
  display: flex;
  align-items: center;
  width: 100%;
}

.content_bottom {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card_action {
  padding: 24px;
}

.button_wrapper {
  width: 180px;
}

.card_icon {
  margin-right: 20px;
}

.title_wrapper {
  flex: 1 1 auto;
}

.card_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: $black-90;
}

.card_subtitle {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $black-40;
}

.card_button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.card_description {
  width: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: $black-90;
      position: relative;
      padding-left: 32px;

      &::before {
        content: '';
        background-color: #daf0e3;
        background-image: url('../../../../images/done.svg');
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

  }
}

.checkmark_wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #DAF0E3;
}

.checkmark {
  width: 12px;
  margin: 4px;
}

.ribbon {
  position: absolute;
  top: 20px;
  right: -28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $green-20;
  width: 120px;
  height: 20px;
  transform: rotate(40deg);
}

.ribbon_text {
  font-size: 10px;
  font-weight: bold;
  line-height: 100%;
  color: $green-40;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;
}

.amazon_wrapper {
  display: flex;
  align-items: center;
}

.amazon_hub {
  margin: 0 8px;
  height: 28px;
}

@include breakpoint(xs) {
  .roommates_form {
    max-width: 100%;
  }

  .card_item {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .card_content {
    padding: 0;
  }

  .content_top {
    display: block;
  }

  .content_bottom {
    margin-top: 20px;
    flex-direction: column;
  }

  .card_icon {
    margin-bottom: 12px;
  }

  .card_title {
    width: 100%;
  }

  .card_subtitle {
    width: 100%;
  }

  .card_action {
    width: 100%;
    margin-top: 20px;
    padding: 0;
  }

  .card_button {
    height: 36px;
    padding: 5px 0;
    border-radius: 18px;
    font-size: 12px;
    font-weight: 300;
    line-height: 26px;
  }

  .button_wrapper {
    width: 100%;
  }

  .ribbon {
    width: auto;
    top: 28px;
    right: 20px;
    border-radius: 10px;
    padding: 2px 12px;
    transform: none;
  }

  .amazon_hub {
    margin-right: 0;
  }

  .checkmark_wrapper {
    margin-right: 20px;
  }
}

@include breakpoint(sm) {
  .content_top {
    display: block;
  }

  .card_action {
    width: 100%;
    margin-top: 20px;
    padding: 0;
  }

  .card_button {
    margin: 0 auto;
    display: block;
  }

  .button_wrapper {
    width: 100%;
  }
}

.reschedule {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.history_ticket {
  background-color: rgba(255,255,255,0.85);
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 25px;
  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
  }
  .title_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    p {
      font-size: $font-size-1;
    }
  }
}

.oval {
  height: 36px;
  width: 36px;
  border-radius: 100px;
  background: linear-gradient(180deg, #C2C5C9 0%, #6F777F 100%);
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: $white;
    font-size: $font-size-3;
    cursor: default;
    font-weight: $med;
  }
}

.appt_info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.appt_date {
  font-size: $font-size-2;
}

.appt_time {
  font-size: $font-size-1;
  color: $black-40;
}

.location_info{
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid $black-20;

  svg {
    margin-right: 12px;
  }

  span {
    padding-right: 12px;
  }
}
