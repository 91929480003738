@use '~@moved/ui/src/sondheim/common' as *;

.company_card {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: $radius-8;
  padding: $sp-20 $sp-24;
  box-shadow: $shadow-2;
  border: 1px solid transparent;
  transition: box-shadow 0.2s;

  .reviews {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .stars {
    font-size: 14px;
  }

  .icon {
    max-height: 85px;
    max-width: 130px;
  }

}
