@use '~@moved/ui/src/sondheim/common' as *;

.timelineScroller {
  margin-bottom: -$sp-48;
  position: relative;
}

// override overflow to allow arrows to not get clipped
:global(.react-horizontal-scrolling-menu--inner-wrapper) {
  .timelineScroller & {
    overflow-y: visible;
  }
}

.itemWrapper {
  flex: 1 1 auto;
  width: 100%;
}

%scrollButton {
  position: relative;
  // Arrow Icons
  svg {
    position: absolute;
    top: $sp-20;
    transition: color 0.2s;
    color: $iconSingleEnabled;
    --color-1: currentColor;
    transition: opacity 0.2s;
    cursor: pointer;
    &:hover { color: $iconSingleHover; }
  }
  // Faded Gradient Overlay
  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 3;
    transition: width 0.3s;
  }
  // Hidden state
  &.disabled {
    pointer-events: none;
    svg { opacity: 0; }
    &::before { width: 0; }
  }
}

.leftArrow {
  @extend %scrollButton;
  svg { right: $sp-20; }
  &::before {
    left: 0;
    background-image: linear-gradient(to left, transparent 0%, $backgroundTertiary 100%);
  }
}
.rightArrow {
  @extend %scrollButton;
  svg { left: $sp-20; }
  &::before {
    right: 0;
    background-image: linear-gradient(to right, transparent 0%, $backgroundTertiary 100%);
  }
}
