@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  max-width: 1180px;
  margin: 40px auto;
}

.location_data {
  display:flex;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 20px;
  align-items: center;
  @include breakpoint([xs,sm]) {
    flex-direction: column;
  }
}

.address_block {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.location_map {
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  margin: -4px;
  border: 1px solid $border-color;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-color: $black-10;
  @include breakpoint([xs,sm]) {
    margin: 0;
  }
}

.location_address {
  flex: 1 1 auto;
  padding: 0 20px 0 24px;
}

.instructions_link {
  @include breakpoint([xs,sm]) {
    padding-top: 12px;
  }
}

.label {
  display: block;
  text-transform: uppercase;
  font-size: $font-size-0;
  line-height: 16px;
  color: $black-50;
  letter-spacing: 1px;
  font-weight: $med;
  margin-bottom: 4px;
}

.display_value {
  font-size: $font-size-2;
  line-height: 20px;
}

.button {
  @extend %btn-gray;
  @extend %btn--small;
  font-size: $font-size-2;
}
