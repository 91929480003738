@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  max-width: 1180px;
  padding: 0 20px;
  margin: 40px auto;
}

.lease_data {
  display:flex;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 20px;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

.address_block {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.lease_map {
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  margin: -4px;
  border: 1px solid $border-color;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-color: $black-10;
  @include breakpoint([xs]) {
    margin: 0;
  }
}

.lease_address {
  flex: 1 1 auto;
  padding: 0 20px 0 24px;
}

.lease_attribute {
  flex: 1 1 auto;
  border-left: 1px solid $border-color;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  @include breakpoint([xs]) {
    flex: 1 1 auto;
    padding: 12px 0 0;
    margin: 16px 0 0;
    border-left: none;
    border-top: 1px solid $border-color;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.unit {
    flex: 1 1 auto;
  }
}

.label {
  display: block;
  text-transform: uppercase;
  font-size: $font-size-0;
  line-height: 16px;
  color: $black-50;
  letter-spacing: 1px;
  font-weight: $med;
  margin-bottom: 4px;
}

.display_value {
  font-size: $font-size-2;
  line-height: 20px;
}
