@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Book Movers Coupon
---------------------------------------- */

.coupon {
  margin-top: 30px;
  background-image: linear-gradient(to left, #0f0f11, #383b40);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &::before, &::after {
    content: "";
    height: 16px;
    width: 8px;
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    margin-top: -8px;
    background-color: $white;
    border-radius: 0 100px 100px 0;
  }
  &::after {
    right: 0;
    left: auto;
    border-radius: 100px 0 0 100px;
  }
}

.coupon_text {
  display: flex;
  flex-direction: column;
  color: $black-40;
  font-size: $font-size-1;
  max-width: 60%;
  .coupon_title {
    color: $white;
    font-size: 16px;
  }
}

.coupon_amount {
  color: #fff;
  font-size: $font-size-4;
  font-weight: $med;
}
