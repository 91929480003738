@use '~@moved/ui/src/sondheim/common' as *;

.card {
  padding: 8px 8px 24px 8px;
  border: 1px solid transparent;
  transition: border-color 0.2s;
  cursor: pointer;
  max-width: 392px;
  &.selected {
    border-color: $borderAccent;
  }
}

svg.selected {
  --color-1: #{$iconOnColor};
  --color-2: #{$iconMultiAccent};
}

.image {
  border-radius: $radius-4;
  width: 100%;
  height: auto;
}
