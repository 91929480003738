@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  display: flex;
  align-items: center;
  line-height: 30px;
  font-weight: $med;
  font-size: $font-size-0;
  color: $black-40;
  margin: 0;
  margin-top: -30px;
  @include breakpoint(xs) {
    margin-top: 0;
  }
}

.form {
  flex: 1 1 auto;
}
