@use '~@moved/ui/src/sondheim/common' as *;

.wrapper > div {
  height: 100%;
  padding: 0;
  background-color: $backgroundPrimary;
}

.container {
  height: 100%;
  width: 100%;
  & > div {
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
}
