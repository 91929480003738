@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  width: 100%;
}

.section {
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0;
  }
}

.section_title {
  color: $black-90;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

$providerMargin: 12px;

.form {
  margin-top: 60px;
  .consent {
    margin-bottom: 0 !important;
  }
}

// Provider options

.provider_logo_container {
  display: flex;
  justify-content: flex-start;
  @include breakpoint([xs,sm]) {
    justify-content: center;
  }
}

.provider_logo {
  max-width: 285px;
  max-height: 36px;
  @include breakpoint(xs) {
    max-width: 100%;
  }
}

.provider_description {
  @extend %main-body-text;
  margin-top: 8px;
  color: $black-40;
  font-size: $font-size-1;
  @include breakpoint([xs,sm]) {
    justify-content: center;
    text-align: center;
  }
}

.provider_links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 130px;
  margin-left: 20px;

  @include breakpoint([xs,sm]) {
    align-items: center;
    margin-left: 0;
    margin-top: 20px;
    border-top: 1px solid $black-20;
    padding-top: 20px;
    width: 100%;
  }


  .provider_cta {
    margin-bottom: 12px;
    font-size: $font-size-3;
    font-weight: 300;
    color: $black-100;
    flex-wrap: nowrap;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover, &:active, &:focus {
      color: $blue-40;
      .cta_icon {
        --color-1: currentColor;
        --color-2: currentColor;
      }
    }
  }
}

.cta_icon {
  margin-right: 8px;
  --color-1: #{$black-90};
  --color-2: #{$black-90};
}

.chosen_provider {
  background-color: $white;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);

  .name {
    margin-right: 0px;
  }

  .provider_links {
    min-width: auto;
  }
}

.gridrewards_link {
  color: #{$link};

  &:hover {
    color: #{$link-hover};
  }
}
