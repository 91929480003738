@use '~@moved/ui/src/balazs/common/all' as *;

.questions {
  margin-top: 30px;
  margin-bottom: 60px;
  @include breakpoint([xs]) {
    margin-top: 20px;
  }
}

.answer {
  padding: 0 0 20px 0;
  border-bottom: 1px solid $black-20;
}
