@use '~@moved/ui/src/balazs/common/all' as *;

.dashboard {
  background: $bg-light-opaque;
  flex: 1 0 100%; // can be longer but never shorter than full height
  display: flex;
  align-items: center;
}
.content {
  flex: 0 1 966px; // can be narrower than 924px but never wider
  max-width: 100%;
  margin: 0 auto;
  padding: $headerHeight 20px;
  @include breakpoint([xs]) {
    padding: $mobileHeaderHeight 20px;
  }
}
.headliner {
  text-align: left;
  margin-bottom: 56px;
  @include breakpoint([xs]) {
    margin-bottom: 32px;
  }
  .back_link {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
  }
  .back_icon {
    margin-right: 8px;
    --color-1: currentColor;
  }
}
.section {
  margin-bottom: 72px;
  @include breakpoint([xs]) {
    margin-bottom: 56px;
  }
}
.section_header {
  display: flex;
  margin-bottom: 30px;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
  .heading_column {
    flex: 1 1 auto;
  }
  .action_column {
    flex: 0 1 auto;
    display: flex;
    &.enter {
      opacity: 0;
    }
    &.enter-active {
      opacity: 1;
      transition: opacity 0.3s;
    }
    &.exit {
      opacity: 1;
    }
    &.exit-active {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
  h4 {
    margin-bottom: 4px;
  }
}

.form_wrapper {
  width: 100%;
}
.form_fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}
