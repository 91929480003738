@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint([xs, sm]) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.uploader {
  user-select: none;
  flex: 1 1 auto;
  max-width: 500px;
  @include breakpoint([xs,sm]) {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.requirements {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  width: 335px;
  max-width: 100%;
  margin-left: 20px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $med;
    font-size: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(21, 22, 24, 0.101961);
    margin-bottom: 20px;
    & > span {
      margin-left: 10px;
    }
  }

  ul {
    padding-left: 20px;
    line-height: 26px;
    li {
      &::marker {
        font-size: $font-size-4;
        color: $black-20;
      }
      font-size: $font-size-1;
      color: $black-100;
    }
  }

  @include breakpoint([xs,sm]) {
    width: 100%;
    margin: 20px 0 0 0;
  }
}

.badge_wrapper {
  margin-left: 20px;
  @include breakpoint([xs,sm]) {
    background-color: $black-20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 16px 24px 36px;
    margin: 24px 0 0 0;
    width: 100%;
  }
}

.preview_title {
  display: none;
  @include breakpoint([xs,sm]) {
    display: block;
    color: $black-60;
    font-size: $font-size-2;
    margin-bottom: 28px;
    width: 100%;
  }
}

.badge {
  background-color: $white;
  border-radius: 8px;
  padding: 16px 16px 30px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
  width: 256px;
  max-width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 346px;

  @include breakpoint([xs,sm]) {

  }
}

.preview {
  width: 216px;
  height: 216px;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.circle {
  width: 20px;
  height: 20px;
  margin-bottom: 20px;
  background: $black-20;
  display: block;
  border-radius: 100px;
  box-shadow: inset 0 4px 10px 0 rgb(0 0 0 / 6%);
}

.id {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  text-align: center;
}

.name {
  font-size: $font-size-4;
  font-weight: $med;
}

.breed {
  font-size: $font-size-2;
  font-weight: $regular;
  color: $black-40;
}

.logo {
  margin-top: 30px;
  width: 115px;
  max-width: 100%;
}
