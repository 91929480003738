@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Choose Moved screen
---------------------------------------- */
.map {
  height: 256px;
  width: 100%;
  margin-bottom: -64px;
  margin-top: -40px;
  background-image: url('../../../../images/map.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @include breakpoint(xs) {
    background-size: cover;
    height: 200px;
  }
}
.card_row {
  display: flex;
  justify-content: space-between;
  @include breakpoint(xs) {
    flex-direction: column;
  }
}
.card {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 3px 8px rgba(0,0,0,0.03);
  margin-bottom: 16px;
  margin-right: 20px;
  flex: 0 1 33.333%;
  justify-content: space-between;
  @include breakpoint(xs) {
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  &:last-child {
    margin-right: 0;
  }
  &.illustrated_bg {
    flex: 0 0 100%;
    padding: 24px 220px 24px 24px;
    background-image: url('../../../../images/people.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 80%;
    @include breakpoint(xs) {
      display: block;
      padding: 32px;
      background-image: none;
    }
  }
}
// .subtitle {
//   color: $black-40;
//   font-size: $font-size-3;
// }
.card_icon {
  margin-bottom: 8px;
  @include breakpoint(xs) {
    margin-bottom: 0;
    margin-right: 8px;
  }
  &.blue {
    --color-1: #{$blue-50};
  }
  &.green {
    --color-1: #{$white};
    --color-2: #{$green-40};
  }
  &.red {
    --color-1: #{$white};
    --color-2: #{$red-40};
  }
  &.orange {
    --color-1: #{$orange-30};
    --color-2: #{$orange-50};
  }
  &.brown {
    --color-1: #{$brown-40};
    --color-2: #{$brown-30};
  }
  &.purple {
    --color-1: #{$purple-40};
    --color-2: #{$purple-30};
  }
}
.card_text {
  color: $black-90;
  @include breakpoint(xs) {
    flex: 1 1 auto;
  }
}
