@use '~@moved/ui/src/sondheim/common' as *;

.container {
  display: flex;
  border: 1px solid $borderSecondary;
  border-radius: $radius-12;
  padding: $sp-20;
  gap: $sp-24;
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.divider {
  border-left: 1px solid $borderSecondary;
  flex: 0 0 none;
  @include responsive(mobile) {
    border-left: none;
    border-top: 1px solid $borderSecondary;
  }
}

.dataBlock {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  gap: $sp-20;
}

.map {
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  margin: -4px;
  border: 1px solid $borderSecondary;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-color: $black-10;
  @include responsive(mobile) {
    margin: 0;
  }
}

.editButton {
  flex: 0 0 auto;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s;
  background-color: $backgroundTertiary;
  border-radius: $radius-full;
  padding: $sp-8;
  cursor: pointer;
  .dataBlock:hover & {
    opacity: 1;
  }
  &:hover {
    background-color: $ctaSecondaryHover
  }
  @include responsive(mobile) {
    opacity: 1;
  }
}
