@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Task Launcher layout
---------------------------------------- */

.card {
  display: flex;
  background-color: #fefefe;
  border-radius: 20px;
  box-shadow: 0 10px 15px 10px rgba(0,0,0,0.04);
  padding: 30px;
  margin-bottom: 30px;
  transition: transform ease-in 0.2s, box-shadow ease-in 0.2s;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  opacity: 0.9;
  &:hover {
    z-index: 2;
    transform: scale(1.02);
    box-shadow: 0 30px 80px 0 rgba(0,0,0,0.05);
    opacity: 1;
    background-color: $white;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint([xs]) {
    padding: 20px;
    margin: 0 -20px 20px -20px;
    border-radius: 0;
  }

  .btn_task {
    text-align: center;
    justify-content: center;
  }
}

.card_done {
  opacity: 0.6;
}

.card_locked {
  background-color: $white;
  opacity: 0.7;
  z-index: 2;
  cursor: default;
  &:hover {
    opacity: 0.7;
  }
}

.copy_button_area {
  display: flex;
  flex: 1 1 auto;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

.icon {
  flex: 0 0 auto;
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint([xs]) {
    width: 32px;
    height: 32px;
    svg {
      width: 32px !important;
      height: 32px !important;
    }
  }
}

.copy {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 20px;
  h2 {
    font-size: $font-size-6;
    font-weight: $light;
    color: $black-90;
    margin-bottom: 10px;
  }
  p {
    color: $black-40;
    margin: 0;
  }
  @include breakpoint([xs]) {
    padding: 0 12px;
    h2 {
      font-size: $font-size-4;
    }

  }
}

.buttons {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include breakpoint([xs]) {
    margin-top: 15px;
    padding: 0 12px;
    justify-content: flex-start;
  }
}

.btn_image {
  margin-left: -18px;
  margin-right: 10px;
}

.btn_locked {
  @extend .btn_task;
  background-color: $black-20;
  color: $black-40;
  cursor: default;
  &:hover, &:focus {
    background-color: $black-20;
    color: $black-40;
  }
}

.task_popover {
  max-width: 276px;
  border: none;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.02), 0 6px 30px 0 rgba(0,0,0,0.06);
  padding: 0;
  z-index: 5;
  @include breakpoint(xs) {
    top: -4px !important;
  }
  @include breakpoint([sm,md,lg]) {
    left: -4px !important;
  }
}

.popover_arrow {
  &::before {
    display: none !important;
  }
}

.popover_wrapper {
  padding: 12px 18px;
}

.popover_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}

.popover_icon {
  margin-right: 8px;
}

.popover_title {
  margin: 0;
  color: $black-90;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.popover_message {
  color: $black-40;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}
