@use '~@moved/ui/src/sondheim/common' as *;

.train {
  padding: $sp-20 0;
  margin: $sp-20 0;
}

.track {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: $sp-2;
    top: $sp-20;
    bottom: $sp-20;
    left: 19px; // speicific to the design
    background-color: $backgroundTertiary;
    z-index: 1;
  }
}

.stop {
  display: flex;
  position: relative;
  z-index: 3;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  margin-top: 36px;

  .number {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include labelM;
    font-weight: 600;
    color: $contentSecondary;
    background-color: $backgroundTertiary;
    height: $sp-28;
    width: $sp-28;
    border-radius: $radius-full;
    user-select: none;
    transition: background-color 0.2s ease-in-out;
  }

  .text {
    flex: 1 1 auto;
    color: $contentSecondary;
    margin-left: 15px;
    user-select: none;
  }

  &.clickable {
    cursor: pointer;
    .text {
      color: $contentPrimary;
    }
    &:hover {
      .text {
        text-decoration: underline;
      }
    }

  }
  &.current {
    cursor: default;
    &:hover .text {
      text-decoration: none;
    }
  }
  &.completed {
    .number {
      background-color: $backgroundAccent;
      color: $white;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.dotted {
  display: flex;
  height: 40px;
  position: relative;
  margin-top: 36px;

  &:after {
    content: "";
    display: block;
    width: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 19px;
    z-index: 3;
    background-image: linear-gradient($black-10, 40%, rgba(255,255,255,0) 0%);
    background-position: center;
    background-size: 2px 8px;
    background-repeat: repeat-y;
  }
}

.unknown {
  height: 40px;
  width: 40px;
  margin-top: 36px;
  display: block;
}

.lines {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  transition: height ease-in-out 0.6s;
  overflow: hidden;

  .circle {
    height: 40px;
    width: 40px;
    border: 2px solid transparent;
    margin-top: 36px;
    display: block;
    border-radius: 40px;
    background-color: transparent;
    z-index: 3;
    position: relative;
    transition: background-color 0.1s ease-in, border-color 0s ease-out;
    &:first-child {
      margin-top: 0;
    }
    &.active_circle {
      background-color: $black-10;
      border-color: $backgroundAccent;
      transition: background-color ease-out 0.2s, border-color ease-in-out 0.3s;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 2px;
    position: absolute;
    top: 6px;
    bottom: 0;
    left: 19px;
    background-color: $backgroundAccent;
  }
}

.subStep {
  display: flex;
  padding-left: 16px;
  position: relative;
  z-index: 3;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
  cursor: default;
  .dot {
    flex: 0 0 auto;
    background-color: $backgroundTertiary;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    user-select: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    transition: background-color ease-in-out 0.2s;
  }
  .text {
    margin-left: 24px;
    flex: 1 1 auto;
    color: $contentSecondary;
    user-select: none;
  }

  &.subStepActive {
    cursor: pointer;
    .text {
      color: $contentPrimary;
    }
    .dot {
      background-color: $backgroundAccent;
    }

    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
}

.subCircle {
  height: 36px;
  display: block;
}
