@use '~@moved/ui/src/balazs/common/all' as *;

.section {
  margin-bottom: 48px;
  @include breakpoint(xs) {
    margin-bottom: 32px;
  }
}

.title {
  color: $black-40;
  font-size: $font-size-0;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 12px;
}
