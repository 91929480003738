@use '~@moved/ui/src/sondheim/common' as *;
@use 'sass:math';

$imgHeight: 372;
$imgWidth: 924;

.container {
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($imgHeight,$imgWidth) * 100%;
  }
  width: 100%;
  background-image: url('../images/map.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  transition: opacity 0.3s;
}

@keyframes bounce {
  0% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0); }
}

.pin {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin-left: -24px;
  margin-top: -24px;
  height: 48px;
  width: 48px;
  background-image: url('../images/location-pin.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes fade-in-out {
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
}

.pinShadow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: 24px;
  height: 8px;
  width: 24px;
  background: $black-100;
  border-radius: 50%;
  filter: blur(5px);
  animation: fade-in-out 1.5s infinite ease-in-out;
}

@keyframes cross-right {
  0% { opacity: 0.0; left: 0; }
  5% { opacity: 1.0; left: 10%; }
  45% { opacity: 1.0; left: 90%;}
  50% { opacity: 0.0; left: 100% }
  100% { opacity: 0.0; left: 0; }
}
.truckOne {
  position: absolute;
  z-index: 20;
  top: 60%;
  left: 0;
  margin-left: -30px;
  height: 39px;
  width: 60px;
  opacity: 0;
  background-image: url('../images/truck1.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: cross-right 10s infinite linear;
}

@keyframes cross-left {
  0% { opacity: 0.0; left: 100%; }
  5% { opacity: 1.0; left: 90%; }
  45% { opacity: 1.0; left: 10%;}
  50% { opacity: 0.0; left: 0 }
  100% { opacity: 0.0; left: 100%; }
}
.truckTwo {
  position: absolute;
  z-index: 5;
  top: 40%;
  left: 100%;
  margin-left: -30px;
  height: 28px;
  width: 40px;
  opacity: 0;
  background-image: url('../images/truck2.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: cross-left 15s infinite 1s linear;
}
