@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Insurance provider options screen
---------------------------------------- */


.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.03);
  padding: 30px;
  margin-bottom: 20px;
  display: block;
  color: $black-90;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
}
.cta {
  align-self: center;
  max-width: 340px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  & > span {
    z-index: 2;
  }
}

.darken {
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #000;
    content: '';
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover::after {
    opacity: 0.25;
  }
}

.logo {
  max-width: 160px;
  max-height: 50px;
  object-fit: contain;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  & > span {
    text-align: right;
  }
}
