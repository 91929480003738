@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.template {
  perspective: 1px;
  perspective-origin: 50% 206px;
  transform-style: preserve-3d;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint([xs]) {
    perspective-origin: 50% 125px;
  }
}
