@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    AvalonBay Liability Only policy screen
---------------------------------------- */
.title {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  align-items: flex-start;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

.form {
  flex: 1 1 auto;
  margin-right: 40px;
  .title {
    font-weight: $med;
    font-size: $font-size-0;
    color: $black-40;
    margin: 0;
  }

  @include breakpoint([xs]) {
    margin-right: 0;
  }
}

.item_list > div {
  width: 100%;
}

/* --- Things to Know Attributes --- */

.things_content {
  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
    line-height: 1.4;
  }
}

/* ----------------------------------------
    Quote Info Ticket screen
---------------------------------------- */

.ticket {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  width: 360px;
  flex: 0 0 auto;

  h3 {
    font-size: 18px;
    font-weight: $med;
    margin-bottom: 8px;
  }

  @include breakpoint([xs]) {
    width: auto;
  }
}

.ticket_title {
  display: flex;
}

.icon {
  margin-top: 2px;
  margin-right: 12px;
  flex: 0 0 auto;
  --color-1: #0274ed;
  --color-2: #cce3fb;
}

.attributes {
  padding-top: 20px;
  border-top: 1px solid $black-20;
  margin: 20px 0;
}

.points {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  & > li {
    display: flex;
    margin-bottom: 10px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin: 6px 10px 0 4px;
    }

    span {
      padding: 1px 0;
    }

  }

}

.disclaimer {
  font-size: $font-size-1;
  line-height: 120%;
}
