@use '~@moved/ui/src/balazs/common/all' as *;

.agreement_content {
  padding: 0 16px 16px 2px;
}

.label {
  font-size: 16px;
  line-height: 24px;
}
.check {
  display: inline-block;
  line-height: 24px;
  vertical-align: top;
  padding-right: 8px;
}

.accordion {
  color: $primary;
  & .open {
    border-bottom-color: transparent;
  }
}
