@use '~@moved/ui/src/sondheim/common' as *;

.background {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $backgroundPrimary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 730px;
  margin: 44px auto;
  width: 100%;
  @include responsive(mobile) {
    padding: 44px 12px;
    margin: 0;
  }
}

.content {
  padding: 28px 16px;
  @include responsive(mobile) {
    padding: 20px;
  }
}
