@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {
  padding: 16px 0 8px;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.day_title {
  font-size: 10px;
  letter-spacing: 1px;
  color: $text-color;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

.day_number {
  font-size: $font-size-6;
  display: block;
}

.day_month {
  border-top: 1px solid rgba(0,0,0,0.05);
  margin-top: 16px;
  padding-top: 8px;
  font-size: 10px;
  width: 100%;
  letter-spacing: 1px;
  color: $text-color;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

.day_unavailable {
  position: absolute;
  bottom: -10px;
  margin: 0;
  font-size: 12px;
}
