@use '~@moved/ui/src/sondheim/common' as *;

.sizer {
  display: flex;
  justify-content: flex-start;
  background-color: $white;
  max-width: 1078px;
  border-radius: $radius-12;
  box-shadow: $shadow-2;
  overflow: hidden;
}

.container {
  padding: $sp-24 $sp-28;
  max-width: 60%;
  @include responsive(mobile,tablet) {
    max-width: 100%;
    padding: $sp-16;
  }

}
.content {
  // override content block styles (?should we do this?)
  & svg {
    height: 24px !important;
    width: 24px !important;
    margin-top: 4px !important;
    --color-1: #{$white} !important;
    --color-2: #{$black-90} !important;
  }
}

.side_column {
  max-width: 40%;
  display: flex;
  overflow: hidden;
  flex: 1 1 30%;
  flex-shrink: 0;
  min-height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include responsive(mobile,tablet) {
    display: none;
  }
}

.button {
  margin: $sp-28 0;
}
