@use '~@moved/ui/src/sondheim/common' as *;

.calendar {
  display: block;
  position: relative;
  margin: 0 -20px;
}

.close_icon {
  cursor: pointer;
}

.clear_button {
  background: transparent;
  border: none;
  color: $ctaAccentEnabled;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: $ctaAccentHover;
  }
}
