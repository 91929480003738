@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Insured Residents screen
---------------------------------------- */

.form {
  // @extend %styled-form;
}

.tenant_list {
  flex: 1 1 100%;
}

.tenant_head, .tenant_row {
  border-bottom: 1px solid $black-30;
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tenant_head {
  font-weight: $med;
}

.field {
  flex: 0 0 33.3%;
}


.tenant_row {

}
