@use '~@moved/ui/src/sondheim/common' as *;

.form_title {
  margin-bottom: 0 !important;
}

.admin_box {
  display: block;
  color: $contentPrimary;
  background-color: $backgroundSecondary;
  margin-top: $sp-40;
  padding: $sp-24 $sp-28;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
}

.signature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headshot {
  width: 48px;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: $sp-20;
  outline: 1px solid $borderSecondary;
  outline-offset: -1px;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
