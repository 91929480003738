@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Mobile Train section
---------------------------------------- */

.train {
  height: 70px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.station {
  background-color: $white;
  margin: 0 40px;
  height: 6px;
  border-radius: 3px;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.rail {
  background-color: $black-30;
  height: 100%;
  width: 0;
  border-radius: 3px;
  transition: width 0.6s ease-in-out, background-color 0.6s linear;
  &.complete {
    background-color: $green-40;
  }
}

.nav {
  font-size: 28px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black-90;
  background-image: radial-gradient(circle at center , $black-20 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  border-radius: 100px;
  user-select: none;
  background-color: $white;
  &:hover {
    background-size: 200% 200%;
    color: $black-90;
  }
  &:focus, &:active {
    background-color: $black-20;
    background-image: none;
  }
}
