@use '~@moved/ui/src/balazs/common/all' as *;
@use '~@moved/ui/src/sondheim/common' as sondheim;

.card_wrapper {
  @include white-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px 15px;
}

.field {
  flex: 1 0 calc(50% - 7.5px);
  label {
    font-size: $font-size-1;
    color: $black-40;
    margin-bottom: 4px;
  }
  @include breakpoint(xs) {
    flex: 1 0 100%;
  }
}

.content {
  @include breakpoint(xs) {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
}

.flex_wrapper {
  @include breakpoint(xs) {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  text-align: left;
}
.subtitle {
  text-align: left;
  color: $black-40;
  font-size: $font-size-3;
  font-weight: $light;
  margin-bottom: 30px;
  @include breakpoint(xs) {
    margin-bottom: 0;
  }
}

.footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  align-items: stretch;
  @include breakpoint(xs) {
    width: 100%;
    margin-bottom: 50px;
    flex: 0 0 auto;
    align-self: flex-end;
    flex-direction: column;
  }
}

.divider {
  text-transform: uppercase;
  margin: sondheim.$sp-16 0;
  display: flex;
  align-items: center;
  &:before, &:after {
    content: '';
    border-top: 1px solid sondheim.$borderTertiary;
    margin: sondheim.$sp-12 0;
    flex: 1 1 auto;
  }
  &:before { margin-right: sondheim.$sp-20; }
  &:after { margin-left: sondheim.$sp-20; }
}

.reorder_first {
  order: -1;
}
.reorder_last {
 order: 1;
}
