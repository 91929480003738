@use "~@moved/ui/src/balazs/common/all" as *;

.card_holder {
  display: flex;
  flex-wrap: wrap;
}

$cardPadding: 20px;

%base_card {
  border-radius: 12px;
  min-width: 250px;
  width: 312px;
  padding: $cardPadding;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 1;
  min-height: 400px;
  margin-bottom: 24px;
}

.card {
  @extend %base_card;
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  margin-right: 24px;
  position: relative;

  @include breakpoint([xs, sm]) {
    width: 100%;
    margin-right: 0;
  }
}

.circle {
  border-radius: 100px;
  background-color: $black-20;
  $height: 28px;
  width: $height;
  height: $height;
  margin-bottom: 26px;
  box-shadow: inset 0 4px 10px 0 rgb(0 0 0 / 6%);
}

.name {
  font-size: $font-size-5;
  font-weight: $med;
}

.breed {
  margin-bottom: 28px;
}

.photo {
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 8px;
}

.pet_icon {
  background-color: $black-20;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  .spacer {
    width: 100%;
    padding-top: 100%;
    height: 0;
  }

  & > svg {
    max-width: 100%;
    position: absolute;
  }
}

.add {
  @extend %base_card;
  border: 1px solid $black-30;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  justify-content: center;
  transition: background-color ease-out 0.2s, border-color ease-in-out 0.3s;

  .add_button {
    background-color: $black-20;
    border-radius: 100px;
    $dimensions: 44px;
    height: $dimensions;
    width: $dimensions;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    transition: background-color ease-out 0.2s;
  }

  &:hover {
    border: 1px solid $black-40;
    background-color: rgba(255, 255, 255, 0.4);

    .add_button {
      background-color: $black-30;
    }
  }

  &:active {
    border: 1px solid $black-40;
    background-color: rgba(255, 255, 255, 0.8);

    .add_button {
      background-color: $black-40;
    }
  }
}

// actions popover
.popover {
  position: absolute;
  right: $cardPadding;
  top: $cardPadding;
}

.icon_more {
  cursor: pointer;
  --color-1: #{$black-40};
  --color-2: #{$black-40};

  &:hover {
    --color-1: #{$blue-50};
    --color-2: #{$blue-50};
  }
}

.pet_popover {
  border: 0px none transparent;
  box-shadow: 0px 40px 69px rgba(0, 0, 0, 0.04),
    0px 20px 30px rgba(0, 0, 0, 0.027), 0px -8px 11px rgba(0, 0, 0, 0.02),
    0px 2px 3px rgba(0, 0, 0, 0.013);
  border-radius: 13px;
  padding: 12px 0;
  user-select: none;
}

.popover_action {
  padding: 6px 18px;
  width: 216px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & > svg {
    margin-right: 12px;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }

  &.delete {
    &:hover {
      color: $red-50;
      & > svg {
        --color-1: #{$red-50};
        --color-2: #{$red-50};
      }
    }
  }

  &:hover {
    color: $blue-40;
    & > svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
    }
  }
}
