@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint([xs, sm]) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.box {
  display: block;
  background-color: $white;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 12px;

  transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out, color 0.2s ease-in-out;
  color: $black-40;
}

.uploaders {
  max-width: 710px;
  width: 100%;
}

.intro {
  margin-bottom: 24px;

  & > h4 {
    font-weight: $regular;
    color: $black-100;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download_all {
      @extend %faux-link;
    }
  }

  & > p {
    font-size: $font-size-1;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.display_name {
  color: $black-100;
  font-size: $font-size-2;
}

.display_icon {
  margin-right: 24px;
}

.dropzone {
  margin-top: 20px;
}

.instructions {
  margin-top: 20px;
  padding: 0;
}
