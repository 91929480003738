@use '~@moved/ui/src/sondheim/common' as *;


.clickable {
  cursor: pointer;
  min-width: $sp-160;
}

.arrow {
  flex: 0 0 auto;
  transition: color 0.2s;
  color: $iconSingleEnabled;
  .clickable:hover & { color: $iconSingleHover; }
}
