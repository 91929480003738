@use '~@moved/ui/src/sondheim/common' as *;

.card {
  box-shadow: inset 0 0 0 1px transparent;
  transition: box-shadow 0.3s ease-in;
  cursor: pointer;
  &.selected {
    box-shadow: inset 0 0 0 1px $borderAccent;
  }
}
