@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Quote Info Ticket screen
---------------------------------------- */

.ticket {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  width: 360px;
  flex: 0 0 auto;

  h3 {
    font-size: 18px;
    font-weight: $med;
    margin-bottom: 8px;
  }

  @include breakpoint([xs]) {
    width: auto;
  }
}

.certified {
  display: flex;
  p {
    font-size: $font-size-1;
    line-height: 145%;
  }
}

.shield {
  margin-right: 12px;
  flex: 0 0 auto;
  --color-1: #0274ed;
  --color-2: #cce3fb;
}

.attributes {
  padding-top: 20px;
  border-top: 1px solid $black-20;
  margin-top: 20px;
}

.reasons {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;

  & > li {
    display: flex;
    margin-bottom: 18px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      margin-right: 6px;
    }

    span {
      padding: 1px 0;
    }

  }

}
