@use '~@moved/ui/src/sondheim/common' as *;

.box {
  display: block;
  background-color: $backgroundSecondary;
  margin: 0 -16px 20px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  color: $contentPrimary;
}

.box_content {
  background-color: $backgroundSuccessLight;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.confirm_icon {
  margin-bottom: 8px;
  --color-1: #{$iconOnColor};
  --color-2: #{$iconMultiSuccess};
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    margin-right: 8px;
    font-size: 32px;
    font-style: normal;
  }
}

.content {
  display: block;
}

.blurb {
  h2 {
    margin-bottom: 4px;
  }
  padding-bottom: 80px;
  border-bottom: 1px solid $borderTertiary;
  margin-bottom: 40px;
}

.review_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.link {
  background-color: $backgroundSecondary;
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  min-width: 32%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  margin-right: 2%;

  & > img {
    height: 30px
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $backgroundAccentLight;
  }

  @include responsive(mobile) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.refer {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $borderTertiary;
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
}

.refer_icon {
  margin-right: 10px;
  svg {
    --color-1: #{$iconMultiPrimary};
    --color-2: #{$iconMultiPrimaryLight};
  }
}

.marketplace {
  margin: $sp-96 (-$sp-16) 0;
}
