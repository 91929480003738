@use '~@moved/ui/src/sondheim/common' as *;

.title {
  margin-bottom: $sp-24;
}

.content {
  margin-bottom: $sp-24;
  @include responsive(desktop) {
    min-width: 500px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
