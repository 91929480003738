@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Move Side Column
---------------------------------------- */

.ticket {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  width: 360px;
  flex: 0 0 auto;

  h3 {
    font-weight: $med;
    margin-bottom: 8px;
  }

  @include breakpoint([xs,sm]) {
    width: auto;
  }
}

.cancellation {
  display: flex;
  padding-top: 20px;
  border-top: 1px solid $black-20;
  margin-top: 20px;

  h3 {
    font-size: 18px;
  }
}

.fine_print {
  font-size: $font-size-1;
  line-height: 145%;
}

.cancel_time {
  border-bottom: 1px dashed $black-90;
}

.clock {
  margin-right: 12px;
  flex: 0 0 auto;
  --color-1: #40A369;
  --color-2: #40A369;
}

.costs {
  margin-top: 20px;
  .row {

    display: flex;
    justify-content: space-between;
    font-size: $font-size-2;

    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(xs) {
      align-items: flex-start;
    }
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $black-40;
    @include breakpoint(xs) {
      flex: 0 0 auto;
    }
  }
  .value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    @include breakpoint(xs) {
      margin-left: 20px;
    }
  }
}

.amount {
  padding-top: 20px;
  border-top: 1px solid $black-20;
  margin-top: 20px;
  .due {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: $med;

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(xs) {
      align-items: flex-start;
    }
  }
    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include breakpoint(xs) {
        flex: 0 0 auto;
      }
    }
    .value {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: $med;
      text-align: right;
      @include breakpoint(xs) {
        margin-left: 20px;
      }
    }
}

.coupon {
  color: $green-40;
}

.tooltip {
  margin: 0;
  font-size: $font-size-1;
  font-weight: $light;
  line-height: 20px;
  max-width: 600px;
  padding: 12px 16px;
  text-align: left;
}
