@use '~@moved/ui/src/sondheim/common' as *;

.divider {
  border-top: 1px solid $borderSecondary;
  width: 100%;
}

.actions {
  justify-content: flex-end !important;
}
