@use '~@moved/ui/src/sondheim/common' as *;

.wrapper {
  width: 100%;
  max-width: 1600px; // design specific value
  padding: $sp-24 $sp-12 $sp-160;
  min-height: 100%;
  align-items: center;
  @include responsive(mobile) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: $sp-96;
  }
}

.contentContainer {
  width: 100%;
  max-width: 1276px;
}

.section {
  padding-right: $sp-20;
  padding-left: $sp-20;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.accent {
    border-radius: $radius-12;
    padding-top: $sp-96;
    background: $backgroundTertiary;
    @include responsive(mobile) {
      padding: $sp-20;
      border-radius: 0;
    }
    &.timeline {
      padding: $sp-64;
      @include responsive(mobile) {
        padding: $sp-48 $sp-20;
      }
    }
    &.gettingStarted {
      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.03) 0.39%,
          rgba(0, 0, 0, 0.00) 74.58%
        ),
        url('../assets/books-lifestyle.jpg') $backgroundTertiary center 30% / cover no-repeat;
        padding-bottom: 128px; // custom spacing per design
    }
  }
}
