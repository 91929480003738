@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {}

.time_number {
  font-size: 16px;
  display: block;
}

.time_reserved {
  position: absolute;
  bottom: 18px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  .option_mobile & {
    bottom: 0;
  }
}
