@use '~@moved/ui/src/sondheim/common' as *;

.lock {
  margin-right: $sp-8;
  --color-1: #{$iconSingleEnabled};
  --color-2: #{$iconSingleEnabled};
}

.breakdown {
  width: 360px;
  @include responsive(mobile) {
    order: -1;
    width: auto;
  }
}
