@use '~@moved/ui/src/sondheim/common' as *;

.navigate {
  margin-top: $sp-48;
  flex-shrink: 0;
}

.navigate_choice {
  width: 600px;
  display: flex;
  gap: 10px;
  & > * {
    max-width: 340px;
    flex: 1 1 auto;
    & > button {
      width: 100%;
    }
  }
  @include responsive(mobile) {
    width: 100%;
    flex-direction: column;
    gap: 12px;
    & > * {
      max-width: 100%;
    }
  }
}
