@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Progress Bar layout
---------------------------------------- */

.area {
  width: 340px;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.caption {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: $font-size-1;
}
  .numbers {
    color: $black-40;
  }

.bar {
  display: flex;
  span {
    flex: 1 1 auto;
    height: 10px;
    background-color: $white;
    margin-right: 4px;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.06);

    &.complete {
      background-color: $blue-50;
      .done & {
        background-color: $green-40;
        &:first-child {
          background: linear-gradient(90deg, lighten($green-40, 5%) 0%, $green-40 100%);
        }
      }
      &:first-child {
        background: linear-gradient(90deg, lighten($blue-50, 20%) 0%, $blue-50 100%);
      }
    }

    &:first-child {
      border-radius: 20px 0 0 20px;
    }
    &:last-child {
      border-radius: 0 20px 20px 0;
      margin-right: 0;
    }
  }
}
