@use '~@moved/ui/src/balazs/common/all' as *;

.form_wrapper {
  flex: 0 1 520px;
}

.title {
  text-align: left;
}

.subtitle {
  text-align: left;
  color: $black-40;
  font-size: $font-size-3;
  font-weight: $light;
  margin-bottom: 30px;
}

.btn_set_password {
  margin-top: 24px;
}
