@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Move Summary Fields section
---------------------------------------- */

.info {
  margin-bottom: 30px;
  display: flex;;

  &:last-child {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
}

.info_icon {
  background-color: #fff;
  border-radius: 200px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  width: 40px;
  height: 40px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .dot {
    background-color: $black-90;
    border-radius: 200px;
    width: 10px;
    height: 10px;
    display: block;
  }
}

.info_label {
  color: $black-40;
  font-size: $font-size-1;
  margin-bottom: 5px;
}
