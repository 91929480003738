@use '~@moved/ui/src/sondheim/common' as *;

.card {
  padding: 0;
  overflow: hidden;
  background-color: $backgroundPrimary;
  max-width: 808px;
  border-radius: $radius-12;
}

.content {
  flex: 0 1 496px;
  padding: $sp-28 $sp-32;
  min-height: 500px;
  @include responsive(mobile) {
    flex: 1 1 100%;
    padding: $sp-24 $sp-16;
  }
}

.actions {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sideImage {
  background-color: $backgroundTertiary;
  padding: $sp-96 0 $sp-80 $sp-64;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  @include responsive(mobile) { display: none; }
  img {
    width: 248px;
    height: auto;
    box-shadow: $shadow-5;
  }
}
