@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  margin: 0 auto;
  position: relative;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.move_steps_holder {
  @include white-box;
  padding: 8px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

.step_link {
  padding: 20px;
  border-radius: 6px;
  transition: background 0.3s;
  background: transparent;
  margin-right: 8px;
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  min-width: 0;
  user-select: none;
  position: relative;
  z-index: 1;
  color: $text-color;
  &:hover, &:active, &:focus {
    color: $text-color;
  }
  @include breakpoint([xs]) {
    padding: 12px;
    margin-right: 0;
    flex: 1;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $bg-light;
    color: $text-color;
  }
  &.active {
    background: $black-20;
  }
}

.thumbnail {
  height: 80px;
  width: 80px;
  background-size: cover;
  background-color: $black-90;
  background-position: center;
  border-radius: 4px;
  margin-right: 24px;
  flex: 0 0 auto;
  @include breakpoint([xs]) {
    height: 52px;
    width: 52px;
    margin-right: 12px;
  }
}

.column {
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 10px;
  &.action {
    flex: 0 0 auto;
    padding-right: 0;
  }
}

.icon {
  display: inline-block;
  line-height: 16px;
  vertical-align: top;
}

.move_step_label {
  font-size: $font-size-1;
  line-height: 16px;
  @include text-truncate;
  @include breakpoint([xs]) {
    font-size: $font-size-0;
  }
}

.move_step_heading {
  font-size: $font-size-4;
  line-height: 32px;
  color: $black-100;
  @include text-truncate;
  @include breakpoint([xs]) {
    font-size: $font-size-2;
    line-height: 24px;
  }
}
