@use '~@moved/ui/src/balazs/common/all' as *;
@use 'Profile.module';

.headliner {
  position: relative;
}

.back_icon {
  position: absolute;
  left: -75px;
  top: 50%;
  margin-top: -24px;
  border: 1px solid $black-30;
  border-radius: 999em;
  padding: 10px;
  transition: border 0.2s, background-color 0.2s;
  @include breakpoint([xs]) {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    display: inline-block;
  }
  &:hover {
    border-color: $black-50;
    background-color: $black-20;
  }
}

.form_fields {
  max-width: 496px;
}
