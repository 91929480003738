@use '~@moved/ui/src/sondheim/common' as *;

.card {
  padding: $sp-12;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
  @include aspectRatio(281, 144);
  border-radius: $radius-4;
  background-color: $backgroundTertiary;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    padding: $sp-16;
  }
}
