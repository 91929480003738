@use '~@moved/ui/src/sondheim/common' as *;

.ad_section {
  margin-bottom: 46px;
}

$adGap: 20px;

.ads_container {
  display: flex;
  flex-wrap: wrap;
  gap: $adGap;
}

.ad_block {
  flex: 1 1 calc(25% - $adGap*0.75);
  min-width: 20%;
  max-width: 50%;
  @include responsive(mobile) {
    max-width: 100%;
    flex: 1 1 auto;
  }
  &.medium {
    flex: 0 1 calc(33.33% - $adGap*0.6667);
  }
  &.large {
    flex: 1 1 calc(50% - $adGap*0.5);
  }
}
