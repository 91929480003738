@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Shared Task Flow Steps
---------------------------------------- */
.holder {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.folder {
  max-width: 660px;
  width: 100%;
}

.task {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 30px 80px;
  min-height: 100%;
  @include breakpoint([xs]) {
    padding: 0px 12px 20px 12px;
  }

}

//IE11 targeted style to give task an explicit
//height so that vertical centering works
_:-ms-fullscreen, :root .task {
  height: 0;
}

.content {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include breakpoint([xs]) {
    align-items: flex-start;
  }
}

.start_over {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.animated_screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background-position: right center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  @include breakpoint([xs]) {
    background-image: none !important;
  }
}

// if a task has a background-image, add it here
// the style name is task followed by the slug-- date, address, special, etc.
// .task_slug {
//   background-image: url('../images/illustrations/paperplane.svg');
// }

// Book Movers
.task_date {
  background-image: url('../images/illustrations/paperplane.svg');
}

.task_address {
  background-image: url('../images/illustrations/wave.svg');
}

.task_special {
  background-image: url('../images/illustrations/tophat.svg');
}

.task_move-size {
  background-image: url('../images/illustrations/beach.svg');
}

.task_moving-quotes {
  background-image: url('../images/illustrations/phone.svg');
}

.task_payment {
  background-image: url('../images/illustrations/piggybank.svg');
}

.task_summary {
  background-image: url('../images/illustrations/success.svg');
}

// Reserve Date & Time
.task_summary-reserve {
  background-image: url('../images/illustrations/thinking.svg');
}

// COI
.task_coi-submit {
  background-image: url('../images/illustrations/checklist.svg');
}

.task_coi-summary {
  background-image: url('../images/illustrations/checklist.svg');
}

//LOFI: backgrounds for lofi tasks
.task_book-contact {
  background-image: url('../images/illustrations/beach.svg');
}

.task_book-options {
  background-image: url('../images/illustrations/wave.svg');
}
//LOFI: end lofi task backgrounds

.navigate {
  margin-top: 20px;
  flex-shrink: 0;
}

.navigate_next {
  width: 340px;
  @include breakpoint([xs]) {
    width: auto;
  }
}

.navigate_choice {
  width: 600px;
  display: flex;
  & > * {
    margin-left: 10px;
    max-width: 400px;
    flex: 1 1 auto;
    &:first-child {
      margin-left: 0;
    }
    @include breakpoint(xs) {
      margin-top: 12px;
      margin-left: 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  @include breakpoint(xs) {
    width: auto;
    flex-direction: column;
  }
}

/* --- Book Movers --- */

.calendar_section {
  width: 400px;
  max-width: 100%;
}
