@use '~@moved/ui/src/balazs/common/all' as *;

// needs to be nested for the added specificity to override stockholm defaults
.coi_container {
  .icon {
    display: inline-block;
    margin: 5px 10px 5px 0;
    vertical-align: top;
  }
  .downloaded {
    border: solid 2px #C5C9CF;
    background-color: #fff;
    padding-top: 3px;
    padding-bottom: 3px;
    .icon {
      margin: 4px 10px 4px 0;
    }
  }
}
.complete_icon {
  background: #DAF0E3;
  border-radius: 999em;
  display: inline-block;
  margin-bottom: 8px;
}
.complete_check {
  height: 64%;
  width: 64%;
  margin: 18%;
}
