@use '~@moved/ui/src/balazs/common/all' as *;

.tooltips_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.tooltip {
  width: 130px;
  min-height: 40px;
  position: absolute;
  bottom: 18px;
  left: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-100;
  border-radius: 8px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $black-100;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
}

.tooltip_green {
  @extend .tooltip;
  background-color: $green-20;
  &:after {
    border-top-color: $green-20;
  }
}

.tooltip_info {
  margin-right: 8px;
}

.tooltip_text {
  color: $white;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.tooltip_green_text {
  @extend .tooltip_text;
  color: $green-60;
}

.tooltip_overlap {
  bottom: 62px;
  &:after {
    display: none;
  }
}
