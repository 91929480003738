@use '~@moved/ui/src/sondheim/common' as *;

.container {
  width: 100%;
  max-width: 300px;
  display: block;
  position: relative;
  margin: 0 auto;
}

.calendar {
  display: inline-block;
  position: relative;
  :global {
    .react-datepicker__header {
      background-color: $backgroundPrimary;
      border-color: $backgroundPrimary;

    }
  }
}

.buttons {
  margin-top: $sp-24;
  gap: $sp-8;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
