@use '~@moved/ui/src/sondheim/common' as *;

.wrapper {
  border: 1px solid $borderTertiary;
  border-radius: $radius-12;
  justify-content: space-between;
  padding: $sp-16;
}

.contentBlock {
  flex: 1 1 33%;
}

.ctaBlock {
  flex: 2 1 66%;
  max-width: 480px;
  min-width: 50%;
}

.logo {
  height: 24px;
  max-width: 100%;
}
