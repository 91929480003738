@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  @include white-box;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  padding: 80px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  position: absolute;
  z-index: 0;
  &.camera { top: 30px; right: -25px; }
  &.piggybank { top: 30px; left: -25px; }
  &.user { bottom: -30px; left: 55px; }
  &.wallet { bottom: -35px; right:15px; }
  @include breakpoint([xs]) {
    display: none;
  }
}

.content {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
}

.title {
  font-size: $font-size-7;
  margin-bottom: 4px;
}

.description {
  color: $black-50;
  margin-bottom: 30px;
}
