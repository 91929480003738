@use '~@moved/ui/src/sondheim/common' as *;


.inputButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: $radius-8;
  padding: $sp-8 $sp-12;
  gap: $sp-12;

  &:hover {
    background-color: $backgroundOverlayLight; // Darker hover color
    border-radius: $radius-8;

    .deleteIcon {
      opacity: 1; // Show the delete icon on hover
    }
  }
}

.inputButton {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  gap: $sp-8;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: $radius-8;

  &:hover {
    background-color: transparent;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.deleteIcon {
  opacity: 0; // Hide by default
  transition: opacity 0.2s ease-in-out;
}
