@use '~@moved/ui/src/balazs/common/all' as *;

.section {
  margin-bottom: 24px;
}

.section_label {
  font-size: $font-size-3;
  font-weight: 450;
  margin-bottom: 16px;
}

.provider {
  @include white-box;
  border-radius: 12px;
  box-shadow: 0 3px 8px rgba(0,0,0,0.05);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.column {
  flex: 1 1 50%;
  &.actions {
    flex: 0 0 auto;
    margin-left: 50px;
    @include breakpoint(xs) {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
.contact_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 100%;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid $border-color;
  &:first-child {
    border: none;
    padding: 0;
  }
  & > * {
    flex: 0 0 auto;
    margin-left: 10px;
    &:first-child {
      flex: 1 1 auto;
      margin-left: 0;
    }
    @include breakpoint([xs]) {
      margin-bottom: 10px;
      flex: 1 1 auto;
    }
  }
  @include breakpoint([xs]) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
}

.logo {
  display: block;
  max-height: 34px;
  max-width: 144px;
  margin-bottom: 12px;
}

.content {
  padding-bottom: 0;
}

a.button {
  padding: 6px 14px 6px 22px;
  font-size: $font-size-2;
  @include breakpoint(xs) {
    padding: 12px 20px 12px 28px;
    height: 50px;
  }

}

.show_more {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-20;
  border-radius: 12px;
  padding: 18px;
  transition: background-color 0.6s;
  cursor: pointer;
  &:hover {
    background-color: $black-30;
  }
}
