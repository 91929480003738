@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Book Movers flow confirmation screen
---------------------------------------- */

.content {
  display: flex;
  align-items: flex-start;
  @include breakpoint([xs]) {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

/* --- Main Column --- */

.confirmation_content {
  flex: 1 1 auto;
  margin-right: 40px;
  @include breakpoint([xs]) {
    margin-right: 0;
    width: 100%;
  }
}

.summary {
  margin-bottom: 30px;
  @include breakpoint(xs) {
    margin-bottom: 40px;
  }
}

.summary_fields {
  margin-top: 20px;
}
