@use '~@moved/ui/src/sondheim/common' as *;

.formWrapper {
  flex: 0 1 520px;
}

.view_back {
  position: absolute;
  top: 25px;
  left: 50px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  color: $contentSecondary;
  transition: color 0.2s;
  &:hover {
    color: $contentPrimary;
  }
  @include responsive(mobile) {
    display: none;
  }
}

.view_toggle {
  position: absolute;
  top: 25px;
  right: 50px;
  color: $contentSecondary;
  @include responsive(mobile) {
    display: none;
  }
}

.wrapper {
  flex: 0 1 770px;
  @include responsive(mobile) {
    flex: auto;
    width: 100%;
    justify-content: center;
  }
}

.stickyFooter {
  padding-bottom: $sp-96;
  @include responsive(mobile) { padding-bottom: $sp-160; }
  & img {
    position: absolute;
    bottom: 0;
    width: 770px;
    display: block;
    border-radius: $radius-12 $radius-12 0 0;
  }
}
