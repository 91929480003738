@use '~@moved/ui/src/balazs/common/all' as *;

.dashboard {
  background: $bg-light-opaque;
  flex: 1 0 100%; // can be longer but never shorter than full height
  display: flex;
  align-items: center;
}
.content {
  flex: 0 1 966px; // can be narrower than 924px but never wider
  max-width: 100%;
  margin: 0 auto;
  padding: $headerHeight 20px;
  @include breakpoint([xs]) {
    padding: $mobileHeaderHeight 20px;
  }
}
.headliner {
  display: flex;
  text-align: left;
  margin-bottom: 40px;
  .actions {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 40px;
  }
}

.moves {
  width: 100%;
  max-width: 980px;
  text-align: left;
  margin-bottom: 44px;
  &:last-child {
    margin-bottom: 0;
  }
  label {
    display: block;
    margin-bottom: 12px;
  }
}

.no_results {
  @include white-box;
  background-color: $bg-light;
  border: 1px solid $bg-light;
}
