@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  width: 100%;
}

.section {
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0;
  }
}

.section_title {
  color: $black-90;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.provider_container {
  display: flex;
  width: 550px;
  background-color: $black-20;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 22px 24px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(xs) {
    width: 100%;
    height: auto;
    padding: 16px;
    flex-direction: column;
  }
}

.provider_content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-right: 24px;
  @include breakpoint(xs) {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

//IE11 targeted style to add a unit to flex-basis
//so that the content doesn't expand past the container
_:-ms-fullscreen, :root .provider_content {
  flex: 1 1 0%;
}


.provider_links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.provider_logo_container {
  display: flex;
  justify-content: flex-start;
}

.provider_logo {
  max-width: 285px;
  max-height: 36px;
  @include breakpoint(xs) {
    max-width: 100%;
  }
}

.provider_description {
  margin-top: 8px;
  color: $black-40;
  font-size: 14px;
  line-height: 18px;
}

.provider_cta {
  width: 150px;
  height: 36px;
  margin-bottom: 8px;
  padding: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(xs) {
    width: 100%;
    margin-bottom: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
