@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Parallax splash image
---------------------------------------- */

.splash {
  color: $white;
  position: relative;
  height: 412px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  z-index: -1;
  transform-style: preserve-3d;
  @include breakpoint([xs]) {
    height: 250px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: 170px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    @include breakpoint([xs]) {
      height: 140px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black-90,0.4);
  }
}

.transition_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform-style: preserve-3d;
  height: 100%;
  transform: translateZ(-1px) scale(2);
}
.splash_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-color: $black-90;
  background-position: center;
  video {
    object-fit: cover;
  }
}

.splash_area {
  flex: 1 1 auto;
}

.splash_container {
  height: 100%;
  z-index: 2;
  position: relative;
  width: $container-max-width;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.splash_content {
  height: 100%;
  padding: 65px 0 56px;
  display: flex;
  align-items: flex-end;
  @include breakpoint([xs]) {
    padding-bottom: 48px;
  }
  &.navigation_padding {
    padding-bottom: 146px;
    @include breakpoint([xs]) {
      padding-bottom: 48px;
    }
  }
}
