@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    View Quotes screen
---------------------------------------- */

$topHeight: 30px;

.form {
  flex: 1 1 auto;
  .title {
    height: $topHeight;
    font-weight: $med;
    font-size: $font-size-0;
    color: $black-40;
    margin: 0;
  }

  @include breakpoint([xs]) {
    margin-right: 0;
  }
}

//IE11 targeted style to add a unit to flex-basis
//so that the content doesn't expand past the container
_:-ms-fullscreen, :root .form {
  flex: 1 1 0%;
}

.item_list > div {
  width: 100%;
}

.badge {
  margin-top: 5px;
  display: inline-block;
  background-color: $green-40;
  color: $white;
  border-radius: 2px;
  padding: 2px 4px 1px;
  text-transform: uppercase;
  font-weight: $semibold;
  letter-spacing: 1px;
  font-size: $font-size-0;
}

.questions {
  margin-top: 60px;

  @include breakpoint([xs]) {
    margin-top: 20px;
  }
}

.answer {
  padding: 20px 0;
  border-bottom: 1px solid $black-20;
}

/* --- Right Label on quote options --- */

.per_hour {
  font-size: $font-size-1;
}

.pre_coupon_rate {
  font-size: $font-size-1;
  color: $blue-50;
  text-decoration: line-through;
}

/* --- Things to Know Attributes --- */

.things_content {
  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
  }
}

.things {
  margin-top: 20px;
  .row {

    display: flex;
    justify-content: space-between;
    font-size: $font-size-2;

    margin-bottom: 15px;
    border-bottom: 1px solid $black-20;
    padding-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
      border-bottom-width: 0;
      padding-bottom: 0;
    }
    @include breakpoint(xs) {
      align-items: flex-start;
    }
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $black-40;
    @include breakpoint(xs) {
      flex: 0 0 auto;
    }
  }
  .value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: $med;
    text-align: right;
    @include breakpoint(xs) {
      margin-left: 20px;
    }
  }
}

p.tooltip {
  margin: 0;
  font-size: $font-size-1;
  font-weight: $light;
  line-height: 20px;
  max-width: 600px;
  padding: 12px 16px;
  text-align: left;
}
