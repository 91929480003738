@use '~@moved/ui/src/sondheim/common' as *;

$defaultMinWidth: $sp-32;
$eventMinWidth: $sp-40;

.wrapper {
  overflow: hidden;
  align-items: center;
  flex: 1 1 auto;
  cursor: default;
  min-width: $defaultMinWidth;
  &.withEvent {
    min-width: $eventMinWidth;
  }
}

.day {
  width: 100%;
  height: $sp-40;
  position: relative;
}

.line {
  width: 100%;
  height: 6px;
  background-color: $contentInversePrimary;
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  z-index: 1;
  .past & {
    background-color: $backgroundInverseSecondary;
  }
  .today & {
    background: linear-gradient(90deg, $backgroundInverseSecondary 50%, $contentInversePrimary 50%);
    padding: 0;
    &::before {
      content: '';
      width: $sp-2;
      height: 32px;
      background-color: $backgroundInverseSecondary;
      border-radius: 2px;
      position: absolute;
      top: -8px;
      left: calc(50% - 1px);
      z-index: 2;
    }
  }
  // .weekend & {
  //   background-color: $backgroundOverlayLight;
  // }
}

.label {
  @include labelM;
  color: $contentSecondary;
  text-align: center;
  user-select: none;
  width: 100%;
  .today & {
    background-color: $backgroundInverseSecondary;
    color: $contentInversePrimary;
    align-self: center;
    padding: 0 $sp-12;
    border-radius: $radius-full;
  }
  // .weekend & {
  //   background-color: $backgroundOverlayLight;
  // }

}

.month {
  @include labelM;
  color: $contentPrimary;
  text-align: center;
  user-select: none;
}
