@use '~@moved/ui/src/sondheim/common' as *;

$shadowColor: darken($backgroundPrimary, 2%);

.divider {
  width: 4px;
  margin: -20px 0;
  background: linear-gradient(0deg, transparent 0, transparent 2px, $shadowColor 2px, $shadowColor 12px, transparent 12px, transparent 14px);
  background-position: center center;
  background-size: 4px 16px;
  background-repeat: repeat;
  position: relative;
  align-self: stretch;
  &:after, &:before {
    content: '';
    position: absolute;
    left: -12px;
    height: 28px;
    width: 28px;
    background: $backgroundPrimary;
    border-radius: 999em;
  }
  &:before {
    top: -14px;
  }
  &:after {
    bottom: -14px;
    box-shadow: $shadow-1 inset;
  }

  @include responsive(mobile) {
    display: none;
  }
}
