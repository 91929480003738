@use '~@moved/ui/src/balazs/common/all' as *;
@use '~@moved/ui/src/sondheim/common' as sondheim;

.form_wrapper {
  flex: 0 1 520px;
  @include breakpoint(xs) {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
  }
}

.view_toggle {
  position: absolute;
  top: 25px;
  right: 50px;
  color: $black-40;
  @include breakpoint(xs) {
    display: none;
  }
}

.content {
  @include breakpoint(xs) {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
}

.flex_wrapper {
  @include breakpoint(xs) {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  text-align: left;
  @include breakpoint(xs) {
    font-size: 24px;
    font-weight: 500;
    line-height: 44px;
  }
}
.subtitle {
  text-align: left;
  color: $black-40;
  font-size: $font-size-3;
  font-weight: $light;
  margin-bottom: 30px;
  @include breakpoint(xs) {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.button {
  @extend %btn-primary;
  @extend %btn--large;
  position: relative;
  padding: 5px 64px;
  flex: 0 0 auto;
  margin-right: 30px;
  .arrow {
    position: absolute;
    right: 24px;
  }
  @include breakpoint(xs) {
    width: 100%;
    max-width: none;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
