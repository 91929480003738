@use '~@moved/ui/src/sondheim/common' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.layout {
  height: 100%;
  display: flex;
  background-color: $backgroundPrimary;
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.sidebar {
  @include responsive(tablet,desktop) {
    width: 264px;
    padding: $sp-24 $sp-32;
    flex: 0 0 auto;
    border-right: 1px solid $borderSecondary;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.building_logo {
  max-width: 144px;
  max-height: 90px;
  display: block;
}
.moved_logo {
  display: block;
  svg {
    width: 94px;
    height: 20px;
    --color-1: #{$black-30};
  }
}

.navigation {
  @include responsive(tablet,desktop) {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    margin: 20px 0;
    padding: 20px 0;
  }
}

.content {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.actions {
  z-index: 100;
  position: absolute;
  top: 30px;
  right: 30px;
}

.button {
  text-align: center;
  cursor: pointer;
  color: $black-40;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(circle at center, $black-20 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  border-radius: 100px;
  user-select: none;
  background-color: $white;
  &:hover {
    background-size: 200% 200%;
    color: $black-90;
  }
  &:focus, &:active {
    background-color: $black-20;
    background-image: none;
  }
}
