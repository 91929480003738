@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Move Summary screen
---------------------------------------- */
.content {
  display: flex;
  align-items: flex-start;
  @include breakpoint([xs, sm]) {
    flex-direction: column;
  }
}

/* --- Main Column --- */

.summary {
  flex: 1 1 auto;
  margin-right: 40px;
  @include breakpoint([xs]) {
    margin-right: 0;
    width: 100%;
  }
}

.info_content {
  padding-bottom: 40px;
  border-bottom: 1px solid $black-20;
  @include breakpoint([xs]) {
    margin-bottom: 30px;
    padding-bottom: 35px;
  }
}


.navigate_next {
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint([xs]) {
    width: auto;
  }
}
