@use '~@moved/ui/src/sondheim/common' as *;

.container {
  overflow: hidden;
  padding: $sp-40;
  border-radius: $radius-12;
}

.content {
  text-align: center;
}

.title {
  white-space: nowrap;
  @include responsive(mobile) {
    white-space: wrap;
  }
}
