@use '~@moved/ui/src/sondheim/common' as *;

.timelineEvent {
  border: 7px solid $backgroundTertiary;
  position: relative;
  z-index: 2;
  border-radius: $sp-20;
  background-color: $backgroundSecondary;
  cursor: pointer;
}

%eventIcon {
  border-radius: $radius-full;
  transition: color 0.2s, background-color 0.2s;
  background-color: $backgroundSecondary;
  svg { color: inherit; }
  &.twoColor svg { --color-1: #{$backgroundSecondary}; }
  &.complete {
    background-color: $backgroundInverseSecondary;
    color: $iconOnColor;
    &.twoColor svg { --color-1: #{$backgroundInverseSecondary}; }
    &:hover {
      background-color: $backgroundInversePrimary;
      color: $iconOnColor;
    }
  }
  &.pastDue, &.pastDue.complete {
    color: $iconMultiError;
    &:hover {
      color: $iconMultiError;
    }
  }
}

.eventCollectionIcon {
  @extend %eventIcon;
  @include labelM;
  padding: $sp-2 $sp-8;
  color: $contentSecondary;
  &:hover { color: $contentPrimary; }
}

.eventIcon {
  @extend %eventIcon;
  padding: $sp-4;
  color: $iconSingleEnabled;
  &:hover { color: $iconSingleHover; }
}
