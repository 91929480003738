@use '~@moved/ui/src/balazs/common/all' as *;
@use '../../../shared/components/styles/tooltips';

.picker_section {
  @include breakpoint(xs) {
    padding: 0;
  }
}

.picker_container {
  margin: 0 auto;
  max-width: $container-max-width;
  padding: 0 12px 0 0;
  @include breakpoint(xs) {
    padding: 0;
  }
}

.roomie_reservation {
  display: block;
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0;
  }
}

.no_dates_title {
  font-size: $font-size-2;
}

.no_dates {
  font-size: $font-size-1;
}

.keep_reservation {
  z-index: 99;
  position: relative;
  margin-bottom: 70px;
}
