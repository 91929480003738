@use '~@moved/ui/src/balazs/common/all' as *;

.tiny {
  width: calc(25% - 8px) !important;
  @include breakpoint([xs]) {
      width: 100% !important;
    }
}

.service_animal_documentation_label {
  display: block;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  color: $black-100;
}
