@use '~@moved/ui/src/sondheim/common' as *;

.content_wrapper {
  display: flex;
  gap: 25px;
  align-items: flex-start;
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.instructions {
  flex: 0 1 670px;
  min-width: 400px;
  min-height: 400px;
  background-color: $white;
  border-radius: $radius-12;
  box-shadow: $shadow-2;
  padding: $sp-20 $sp-28;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include responsive(mobile) {
    flex: 1 1 auto;
    min-width: 100%;
  }
}

$petscreeningBrandColor: #011546;
.petscreeningButton {
  background-color: $petscreeningBrandColor;
  &:hover {
    background-color: darken($petscreeningBrandColor,10%);
  }
}

.side_column {
  max-width: 380px;
  min-width: 150px;
  min-height: 300px;
  display: flex;
  flex: 1 1 30%;
  align-self: stretch;
  border-radius: $radius-12;
  box-shadow: $shadow-2;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include responsive(mobile) {
    display: none;
  }
}

.logo {
  flex: 0 0 auto;
  max-width: 150px;
  max-height: 20px;
  filter: grayscale(100%) opacity(60%);
  vertical-align: middle;
}
