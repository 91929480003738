@use '~@moved/ui/src/sondheim/common' as *;

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $backgroundPrimary;
  position: relative;
  z-index: 1;
  @include scrollable();

  // STYLES FOR PARALLAX HEADER IMAGE ON RESIDENT DASHBOARD
  &.parallax {
    perspective: 1px;
    perspective-origin: 50% 206px;
    transform-style: preserve-3d;
    height: 100vh;
    @include responsive(mobile) {
      perspective-origin: 50% 125px;
    }
  }

}
