@use '~@moved/ui/src/balazs/common/all' as *;

.holder {
  margin-top: 25px;
}

.header {
  color: $black-40;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.chosen_header {
  color: $blue-50;
}

.option_content {
  border-radius: 8px;
  border: 2px solid transparent;
  margin: 12px 0;
  color: $black-90;
  padding: 16px 0 8px;
  min-height: 100px;
  text-align: center;
  cursor: pointer;
  transition: background-color .2s ease, border-color 0.2s ease;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.03);
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:hover {
    border-color: $green-30;
  }

  &.option_time {
    padding: 16px 4px;
  }

  &.option_active {
    background-color: $green-10;
    border-color: $green-40;

    &:hover {
      background-color: $green-20;
      border-color: $green-40;
    }
  }

  &.option_disabled {
    cursor: not-allowed;
    // background-color: rgba(255,255,255,0.7);
    opacity: 0.6;
    color: #999;

    &:hover {
      opacity: 0.6;
      background-color: rgba(255,255,255,0.9);
    }
  }

  &.option_mobile {
    width: 100%;
    height: 52px;
    min-height: 52px;
    margin: 12px 0;
    padding: 14px 4px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

}

// override library component styles (unfortunately not extensible)
// these are used to support the overflow offset required for the tooltips
.day_picker {
  :global(.react-horizontal-scrolling-menu--scroll-container) {
    height: 252px;
    margin-top: -94px;
    @include breakpoint(xs) {
      padding: 0 12px;
    }
  }
  :global(.react-horizontal-scrolling-menu--item) {
    display: flex;
    align-items: flex-end;
  }
}


.item_wrapper {
  min-width: 120px;
  margin: 0 6px;
}

.separator {
  flex: 0;
}
