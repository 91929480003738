@use '~@moved/ui/src/balazs/common/all' as *;

.pet_license_label {
    display: block;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 400;
    color: $black-100;
}

.vaccination_label {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 400;
    color: $black-100;
}
