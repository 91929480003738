@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Task List layout
---------------------------------------- */

.task_area {
  margin-bottom: 90px;
  @include breakpoint([xs]) {
    margin-bottom: 35px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.title_bar {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @include breakpoint([xs]) {
    margin-bottom: 20px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  h3 {
    font-weight: $med;
    @include breakpoint([xs]) {
      font-size: $font-size-3;
      margin-top: 25px;
    }
  }
}
