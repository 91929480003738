@use '~@moved/ui/src/sondheim/common' as *;

.holder {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.task {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 30px 80px;
  min-height: 100%;
  gap: $sp-24;
  @include responsive(mobile) {
    padding: 0 $sp-12 $sp-20 $sp-12;
  }
}
//IE11 targeted style to give task an explicit
//height so that vertical centering works
_:-ms-fullscreen, :root .task {
  height: 0;
}
