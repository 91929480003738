@use '~@moved/ui/src/sondheim/common' as *;

.box {
  display: block;
  color: $contentPrimary;
  background-color: $backgroundSecondary;
  margin: 0 (-$sp-16) $sp-64;
  padding: $sp-16;
  border-radius: 8px;
  box-shadow: $shadow-2;
}

.content {
  background-color: $yellow-20;
  border-radius: 4px;
  padding: $sp-24;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.description {
  margin-bottom: 8px;
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 8px;
    font-size: 32px;
    font-style: normal;
  }
  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
  }
}
