@use 'sass:math';

@mixin aspectRatio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height,$width) * 100%;
  }

  // useful if this is applied to an area with a background image
  background-size: cover;
  background-position: center center;
}
